/* eslint-disable */
  import { LocaleWrapper } from '@empiriecom/module-components/IntlProvider';
  import deepmerge from 'deepmerge';
  import ioLocale from '@empiriecom/mybuy-components/locales/lascananl';
  
  const messages: LocaleWrapper = {
  "nl-NL": {
    "APlusList.headline": "Je bestelde extra services\n",
    "AccountInformation.headline": "Onze bankgegevens",
    "AccountInformation.qrPayment.Or": "Of",
    "AccountInformation.qrPayment.infoText": "Gebruik de QR Code via deze link:",
    "AccountInformation.receiverNameWithBank": "Banknaam",
    "AccountInformation.receiverTitle": "Ontvanger:",
    "AccountInformation.subject": "Betalingsreferentie: <strong>{bankTransferReference}</strong>",
    "AccountSettings.Autologin": "Automatisch inloggen",
    "AccountSettings.Autologin.Headline": "Ingelogd blijven",
    "AccountSettings.Autologin.Text": "Bij je volgende bezoek word je automatisch ingelogd",
    "AccountSettings.Delivery": "Bezorging",
    "AccountSettings.Headline": "Instellingen",
    "AccountSettings.MoreInformation": "meer informatie",
    "AccountSettings.NeighboursDelivery.Headline": "Bezorging bij buren toestaan",
    "AccountSettings.NeighboursDelivery.Infotext": "De bezorger mag mijn pakket bij de buren afgeven als ik niet thuis ben. Het wijzigen van deze bezorgvoorkeur is alleen mogelijk voor artikelen die nog niet gefactureerd zijn.",
    "AccountSettings.NeighboursDelivery.Text": "De bezorger mag mijn pakket bij de buren afgeven als ik niet thuis ben. Het wijzigen van deze bezorgvoorkeur is alleen mogelijk voor artikelen die nog niet gefactureerd zijn.",
    "AccountSettings.Newsletter.Headline": "Nieuwsbrieven",
    "AccountSettings.Newsletter.HeadlineModal": "Voorwaarden",
    "AccountSettings.Newsletter.Infotext": "Schrijf je nu in voor onze nieuwsbrief en ben als eerste op de hoogte van de nieuwste mode, de laatste trends en de scherpste aanbiedingen. Daarnaast profiteer je als nieuwsbriefabonnee van exclusieve acties en unieke kortingen.",
    "AccountSettings.Newsletter.Text": "Schrijf je nu in voor onze nieuwsbrief en ben als eerste op de hoogte van de nieuwste mode, de laatste trends en de scherpste aanbiedingen. Daarnaast profiteer je als nieuwsbriefabonnee van exclusieve acties en unieke kortingen.",
    "AccountSettings.Notifications": "Meldingen",
    "AccountSettings.PostalBankStatement.Headline": "Rekeningoverzichten",
    "AccountSettings.PostalBankStatement.Infotext": "Ik weet dat de kosten hiervoor € 1,50 per rekeningoverzicht bedragen",
    "AccountSettings.PostalBankStatement.Text": "Ik wil mijn rekeningoverzicht thuis op papier ontvangen (á € 1,50 per rekeningoverzicht) ",
    "AccountSettings.ServiceMail.Headline": "Service-informatie",
    "AccountSettings.ServiceMail.Text": "Zo mis je geen informatie over jouw bestellingen.",
    "AccountSettings.ServiceMail.entry0": "Verzendbevestigingen",
    "AccountSettings.ServiceMail.entry1": "Bevestigingen van retourontvangst",
    "AccountSettings.ServiceMail.entry2": "Bevestigingen van betalingsontvangst",
    "AccountSettings.ServiceMail.entry3": "Beoordelingsmails",
    "AccountSettings.ServiceMail.entry4": "Aanmaningen voor aanbetalingen",
    "AccountSettings.ServiceMail.entry5": "-",
    "AccountSettings.ServiceMail.entry6": "-",
    "AccountSettings.ServiceMail.entry7": "-",
    "AccountSettings.ServiceMail.entry8": "-",
    "AccountSettings.ServiceMail.entry9": "-",
    "AccountSettings.ServiceMailInfo.Headline": "We houden je per mail op de hoogte van alles rondom je bestelling. Zo mis je niets. Je ontvangt bijvoorbeeld",
    "AccountSettings.SmsNotification.AddMobileNumber": "Mobiel nummer toevoegen",
    "AccountSettings.SmsNotification.ChangeMobileNumber": "Mobiel nummer wijzigen",
    "AccountSettings.SmsNotification.Headline": "Gratis SMS over de status van je bestelling",
    "AccountSettings.SmsNotification.Infotext": "Wanneer ik tijdens het bestellen kies voor bezorging bij een PostNL Punt of wanneer ik een groot artikel thuis laat bezorgen door Dynalogic wil ik graag een gratis SMS ontvangen wanneer mijn bestelling geleverd wordt.",
    "AccountSettings.SmsNotification.MobilePhoneNumber": "Telefoon:{break}{phoneNumber}",
    "AccountSettings.SmsNotification.Text": "Ontvang meldingen via sms",
    "AccountSettings.eInvoice.Headline": "Factuur",
    "AccountSettings.eInvoice.InfoBenefits": " ",
    "AccountSettings.eInvoice.Infotext": "We informeren je per e-mail zodra er een digitale factuur in je klantenaccount beschikbaar is en je deze kunt downloaden. Zo beschermen we samen het milieu.",
    "AccountSettings.eInvoice.Text": "Kies voor het milieu en tegen papieren facturen.",
    "Aplus.included": "inclusief",
    "BankstatementsContainer.Overview.downloadCardButton": "Downloaden",
    "BankstatementsContainer.Overview.downloadCardDescription": "Om de volledige specificatie te zien, download dit rekeningoverzicht als PDF-bestand (gratis)",
    "BankstatementsContainer.Overview.downloadCardTitle": "Dit rekeningoverzicht downloaden",
    "BankstatementsContainer.Overview.downloadCardWarning": "Er is helaas een fout opgetreden bij het downloaden. Probeer het later opnieuw.",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEnd": "Eindsaldo",
    "BankstatementsContainer.Overview.overviewPeriodBallanceEndOfPeriod": "Af",
    "BankstatementsContainer.Overview.overviewPeriodBallanceNew": "Nieuw saldo",
    "BankstatementsContainer.Overview.overviewPeriodBallancePreviousPeriod": "Saldo vorig rekeningoverzicht",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStart": "Beginsaldo",
    "BankstatementsContainer.Overview.overviewPeriodBallanceStartOfPeriod": "Bij",
    "BankstatementsContainer.Overview.overviewPeriodEmpty": "Je hebt geen recent rekeningoverzicht. Selecteer voor oudere rekeningoverzichten de gewenste periode in de dropdown.",
    "BankstatementsContainer.Overview.overviewPeriodLabel": "Selecteer periode",
    "BankstatementsContainer.Overview.perEmailReceiveCardDescriptionSecondary": "Ik weet dat de kosten hiervoor € 1,50 per rekeningoverzicht bedragen",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMore": "Meer informatie",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreContent": "Ik weet dat de kosten hiervoor € 1,50 per rekeningoverzicht bedragen",
    "BankstatementsContainer.Overview.perEmailReceiveCardLearnMoreHeadline": "Rekeningoverzichten",
    "BankstatementsContainer.Overview.perEmailReceiveCardTitle": "Ik wil dit rekeningoverzicht thuis op papier ontvangen",
    "BankstatementsContainer.Overview.title": "Rekeningoverzichten",
    "BankstatementsContainer.introtext": "Hier vind je jouw rekeningoverzichten. Alle boekingen en je meest recente saldo vind je op je <link>klantenrekening</link>.",
    "BankstatementsContainer.title": "Rekeningoverzicht",
    "BookingContainer.Kim.title": "Hoe bevalt deze pagina?",
    "BookingContainer.bookingOverview.headline": "Mijn transacties",
    "BookingContainer.bookings.headline": "Klantenrekening",
    "BookingListContainer.BookingList.defaultAsyncErrorMessage": "Sorry, we kunnen je transacties momenteel niet tonen. <reload>Laad de pagina opnieuw.</reload> Als de fout zich blijft voordoen, hebben we intern een technisch probleem en zijn we al bezig dit op te lossen. Probeer het later opnieuw. Bedankt voor je begrip.",
    "BookingListContainer.BookingList.emptyBookingListHeadline": "Geen transacties?",
    "BookingListContainer.BookingList.emptyBookingListText": "Je hebt nog niet bij ons besteld: zoek in ons gevarieerde assortiment en bestel jouw nieuwe favoriete artikel",
    "BookingListContainer.BookingList.fullViewSelectionText": "Alle transacties",
    "BookingListContainer.BookingList.invoiceViewSelectionText": "Facturen",
    "BookingListContainer.BookingList.loadMorebutton": "meer laden",
    "BookingListContainer.BookingList.paymentViewSelectionText": "Betalingen",
    "BookingListContainer.BookingList.reimbursementViewSelectionText": "Retouren",
    "BookingListElement.from": "van ",
    "CCLogin.email.label": "E-mailadres",
    "CCLogin.email.placeholder": "naam@provider.nl",
    "CCLogin.label.login": "Inloggen",
    "CCLogin.password.placeholder": "****",
    "CCLogin.title": "Callcenter Login",
    "CCLoginAsCustomer.customerNoLabel": "Klantnummer",
    "CCLoginAsCustomer.dobLabel": "Geboortedatum",
    "CCLoginAsCustomer.submitLabel": "Als klant inloggen",
    "CCLoginAsCustomer.title": "Inloggen als klant",
    "CallcenterLoginContainer.CCADFSLoginResponse.errorMessage": "Jouw verzoek kon niet worden verwerkt. Probeer het opnieuw!",
    "CancelShipmentAction.label.cancel": "Nee, toch niet",
    "CancelShipmentAction.label.cancelOrder": "Artikel annuleren",
    "CancelShipmentAction.label.cancelOrder.confirm": "Annuleren",
    "CancelShipmentAction.label.close": "Sluiten",
    "CancelShipmentAction.modal.title.confirm": "Annulering bevestigen",
    "ChangeIban.cancel": "annuleren",
    "ChangeIban.defaultError": "Er is een technische fout opgetreden",
    "ChangeIban.headline": "IBAN wijzigen",
    "ChangeIban.inputLabel": "Nieuwe IBAN",
    "ChangeIban.inputPlaceholder": "NL0000000000000000",
    "ChangeIban.noDataError": "De gegevens zijn opgeslagen maar kunnen niet worden weergegeven",
    "ChangeIban.save": "Wijzigingen opslaan",
    "ChangePassword.Howto.headline": "Hoe ziet een veilig wachtwoord eruit?",
    "ChangePassword.Howto.list.1": "Combineer hoofd- en kleine letters, cijfers en speciale tekens (bijvoorbeeld: !?$#%)",
    "ChangePassword.Howto.list.2": "Minimaal 8 tot 10 tekens",
    "ChangePassword.Howto.list.3": "Gebruik geen namen of geboortedatums",
    "ChangePassword.Howto.list.4": "Tekenreeksen als \"abc\" of \"123\" kun je beter vermijden",
    "ChangePassword.Howto.list.5": "Gebruik een wachtwoord wat je nergens anders gebruikt",
    "ChangePassword.Howto.text": "Wachtwoorden zoals \"123456\", \"hallo\" en \"wachtwoord\" staan bovenaan de populariteitslijst, maar zijn vrij onveilig. Met deze vijf tips maak je een goed, veilig wachtwoord:",
    "ChangePassword.SecurityHint.headline": "Veiligheid is belangrijk voor ons",
    "ChangePassword.SecurityHint.text": "Je wachtwoord moet uit {minLength}-{maxLength} tekens bestaan en is hoofdlettergevoelig",
    "ChangePassword.errorFromBackend": "Jouw verzoek kan momenteel niet worden verwerkt. Probeer het later opnieuw.",
    "ChangePassword.new.label": "Nieuw wachtwoord *",
    "ChangePassword.passwordsDontMatch": "De twee wachtwoorden komen niet overeen.",
    "ChangePassword.passwordstooLong": "Het opgegeven wachtwoord is te lang (max. {maxLength} tekens)",
    "ChangePassword.passwordstooShort": "Het wachtwoord moet minimaal {minLength} tekens bevatten.",
    "ChangePassword.placeholder": "{minLength}-{maxLength} tekens",
    "ChangePassword.repeat.label": "Nieuw wachtwoord herhalen *",
    "ChangePassword.submit": "Nieuw wachtwoord toepassen",
    "CircleStats.innerTextType.toPay": "Te betalen{break} vóór {date}:",
    "CircleStats.innerTextType.withdraw": "Op {date} wordt{break} dit bedrag{break} geïncasseerd:",
    "CircleStats.sublineTextType.increasedDueToCustomersWish": "Op je eigen{break} verzoek is het{break} bedrag aangepast.",
    "CircleStats.sublineTextType.increasingPossible": "Je mag altijd{break} meer betalen zonder{break} extra kosten.",
    "ContactFormLink": "<link>https://www.lascana.nl/klantenservice/contact</link>",
    "CustomerInformation.BusinessPartnerNo": "Klantnummer: {businessPartnerNo}",
    "CustomerInformation.LogoutText": "Ben je niet {name}?",
    "CustomerInformation.PersonalDataLink": "Mijn persoonlijke gegevens",
    "CustomerInformation.PremiumRenewalDate": "(vernieuwd op {placeholder})",
    "DataExport.Button": "Gegevens downloaden",
    "DataExport.DisabledButton": "Exporteren... ",
    "DataExport.DownloadError": "Helaas is er een fout opgetreden bij het downloaden. Probeer het later opnieuw.",
    "DataExport.DownloadSuccess": "Je gegevens zijn gedownload.",
    "DataExport.Headline": "Gegevensexport",
    "DataExport.NoDownloadText": "Uiteraard kun je de door jou verstrekte persoonlijke gegevens op elk gewenst moment downloaden, zonder dat dit invloed heeft op je klantaccount.{break}{break}Helaas is deze functie alleen beschikbaar in de browser. Log eenvoudig in op je browser met je telefoon of computer om het bestand te downloaden.",
    "DataExport.Subheadline": "Jouw gegevens, jouw zaak.",
    "DataExport.Text": "Natuurlijk kun je jouw persoonlijke gegevens op elk moment downloaden - zonder gevolgen voor je klantenaccount.{break}{break}Alles blijft zoals je gewend bent bruikbaar en opgeslagen bij ons. Zo kun je de gegevens archiveren of gebruiken voor een andere dienst.",
    "EInvoice.Download.Button": "Downloaden en afdrukken",
    "EInvoice.Download.DisabledButton": "Even geduld aub ...",
    "EInvoice.Download.Error": "Helaas is er een fout opgetreden bij het downloaden. Probeer het later opnieuw.",
    "EInvoice.aplus.headline": "Bestelde extra services",
    "EInvoice.error.description": "Er is een fout opgetreden in ons systeem. Probeer het later opnieuw.",
    "EInvoice.footer.businessaddress": "LASCANA | Charles Stulemeijerweg 2 | 5026 RT Tilburg",
    "EInvoice.footer.businessterms": "Lees onze algemene voorwaarden <link>hier</link>",
    "EInvoice.footer.fixed.info.first": "Betaalbaar bij aankoop op rekening binnen 14 dagen, bij termijnbetaling binnen 30 dagen na ontvangst van de levering. Let op, je bent in gebreke zonder verdere aanmaning uiterlijk 30 dagen na ontvangst van deze factuur en het vervallen van de vordering.",
    "EInvoice.footer.fixed.info.second": "Als er een geldig automatische incassomachtiging is, zullen we de verschuldigde bedragen incasseren. Retouren verminderen het incasso bedrag.",
    "EInvoice.footer.fixed.info.third": "Geef alsjeblieft je klantnummer mee in de betaling aan ons. Daarmee kunnen we sneller je betaling verwerken.\nJe klantnummer vind je in je o.a. in je bestelbevestigingsmail of Mijn account terug.",
    "EInvoice.footer.ownership": "De goederen blijven ons eigendom tot volledige betaling.",
    "EInvoice.header.customerNumber.headline": "Klantnummer:",
    "EInvoice.header.deliveryAddress.headline": "Leveringsadres:",
    "EInvoice.header.invoiceAddress.headline": "Factuuradres:",
    "EInvoice.header.invoiceDate.headline": "Factuurdatum:",
    "EInvoice.header.invoiceNumber.headline": "Factuurnummer:",
    "EInvoice.header.paymentType.headline": "Betaalmethode:",
    "EInvoice.invoiceTotals.headline": "Factuur",
    "EInvoice.invoiceTotals.subTotals": "Subtotaal:",
    "EInvoice.invoicetotals.grandTotals": "Totaalbedrag:",
    "EInvoice.paymentplan.headline": "Betalingsplan",
    "EInvoice.paymentplan.showEffectiveInstallmentsHint": "Dit is jouw oorspronkelijke termijn.{break}De huidige termijnen inclusief samenstelling vind je <link>hier</link>.",
    "EInvoice.products.articleNumber": "Artikelnummer: {articlenumber}",
    "EInvoice.products.finalPrice": "Totaalprijs:",
    "EInvoice.products.installments": "in {installmentCount} termijnen",
    "EInvoice.products.paymentMethod": "Betaalmethode: {paymentMethod}",
    "EInvoice.products.pricesHeadline": "Achteraf betalen in één keer",
    "EInvoice.products.productHeadline": "Artikel",
    "EInvoice.products.productPrice": "Waarde van de artikelen:",
    "EInvoice.products.productReturned": "Product is teruggestuurd",
    "EInvoice.products.quantity": "Aantal: {quantity}",
    "EInvoice.products.reclaim.link": "Een probleem met dit artikel melden",
    "EInvoice.products.size": "Maat: {size}",
    "FaqCard.Headline": "Heb je vragen?{br}We staan voor je klaar",
    "FlexikontoContainer.myFlexAccount": "Mijn Flexrekening",
    "Gender.female": "Mevrouw",
    "Gender.male": "Meneer",
    "Joe.disconnect.label": "Koppelverbinding verbreken",
    "Joe.disconnect.not-connected.text": "Uw rekening is niet gekoppeld aan uw Joe rekening.",
    "Joe.disconnect.success": "Uw rekening is succesvol ontkoppeld.",
    "Joe.disconnect.text": "Uw JOE-rekening is gekoppeld aan uw JOE rekening. je spaart Ös bij elke aankoop.",
    "JoeConnectAccountCard.headline": "jö Bonus Club",
    "LoginDecider.accessDenied.error": "Als EU-klant heb je helaas geen toegang tot Mijn account",
    "LoungeContainer.advantage0": "<b>Gratis levering per expeditie</b>{break}U bespaart de verzendkosten voor grote en zware artikelen ter waarde van 34,99€ bij elke levering per expeditie.",
    "LoungeContainer.advantage1": "<b>Gratis verzending bij elke bestelling zonder minimum bestelwaarde</b>{break}Voor u vervalt de minimum bestelwaarde van 75€. U bespaart bij elke bestelling 5,95€.",
    "LoungeContainer.advantage2": "<b>Voorrang bij klantenservice</b>{break}U wordt met voorrang door onze klantenservice bediend.",
    "LoungeContainer.advantage3": "<b>Speciale voucher- & kortingsacties</b>{break}U profiteert van exclusieve aanbiedingen en kortingen.",
    "LoungeContainer.advantage4": "-",
    "LoungeContainer.advantage5": "-",
    "LoungeContainer.advantage6": "-",
    "LoungeContainer.advantage7": "-",
    "LoungeContainer.advantage8": "-",
    "LoungeContainer.advantage9": "-",
    "LoungeContainer.advantagesHeadline": "Als lid van de Flexrekening Voordeelsclub geniet u van exclusieve aanbiedingen:",
    "LoungeContainer.businessPartnerNo": "Mijn klantnummer",
    "LoungeContainer.cancel": "Premiumklantenabonnement opzeggen",
    "LoungeContainer.cancel.info": "Uw voordelen zijn tot een jaar na aanmelding gegarandeerd.{break}We streven er voortdurend naar om de voordelen zo exclusief mogelijk te selecteren en uw voordeelpakket voortdurend uit te breiden. Geef ons de kans om u te blijven overtuigen.{break}Als u niet langer van de voordelen wilt genieten, klik dan alstublieft op de volgende knop.",
    "LoungeContainer.cancel.modal.headline": "Jammer dat je je wilt afmelden!",
    "LoungeContainer.cancel.stay": "Ik wil premiumklant blijven",
    "LoungeContainer.cancelPremium.error": "Dat is helaas niet gelukt. Probeer het opnieuw.",
    "LoungeContainer.cancelPremium.success": "Uw lidmaatschap is beëindigd",
    "LoungeContainer.datastatus": "Gegevensstatus",
    "LoungeContainer.extensionHint": " ",
    "LoungeContainer.freeMembershipHint": " ",
    "LoungeContainer.goal": "Mijn omzetdoel",
    "LoungeContainer.importantOverview": "Het belangrijkste in één oogopslag",
    "LoungeContainer.introtext": "Uw exclusieve clubvoordelen garanderen zorgeloos winkelen!",
    "LoungeContainer.mystatus": "Mijn status",
    "LoungeContainer.orderCount": "Bestellingen zonder verzendkosten",
    "LoungeContainer.orders": "Mijn huidige jaaromzet",
    "LoungeContainer.pageHeadline": "{firstName} Flexrekening Voordeelsclub-pagina",
    "LoungeContainer.renewal": "Lidmaatschapsverlenging op",
    "LoungeContainer.retursHint": "* Retouren worden niet meegerekend in de omzet.",
    "LoungeContainer.savings": "Mijn besparing",
    "LoungeContainer.since": "Flexrekening Clubvoordelen geldig vanaf",
    "LoungeContainer.until": "Flexrekening Clubvoordelen eindigen op",
    "Maturities.RVC.DE.minimalPayment": "Totaal te betalen",
    "Maturities.RVC.DE.openPayments": "daarvan nog open",
    "Maturities.RVC.DeductedPromotion": "waarvan premie voor restdekking",
    "Maturities.RVC.minimalPayment": "huidige minimale termijn",
    "Maturities.RVC.openPayments": "Openstaande betalingen",
    "Maturities.RVC.promotion": "huidige premie betalingsbescherming",
    "Maturities.RVC.sum": "Verschuldigde bedrag",
    "Maturities.RVC.sum.Subheadline": "Uw verschuldigde bedrag",
    "Maturities.accountBalanced": "Je hebt geen saldo",
    "Maturities.bookingsLink": "Alle boekingen",
    "Maturities.currentBalance": "Saldo",
    "Maturities.currentMaturities": "Huidige vervallende betalingen",
    "Maturities.error": "Excuses, het is momenteel niet mogelijk je saldo te berekenen. Laad de pagina opnieuw. Mocht het probleem blijven bestaan, weet dat we er mee bezig zijn om het op te lossen. Probeer het later nog eens. Dank voor je begrip!",
    "Maturities.grandTotal": "Totaal te betalen",
    "Maturities.headline.bookings": "De belangrijkste punten in het kort",
    "Maturities.headline.overview": "Facturen / Betalingen",
    "Maturities.hideDetails": "Details verbergen",
    "Maturities.nextMaturities.headline": "Aankomende vervallende betalingen",
    "Maturities.nextMaturity": "vervalt op ",
    "Maturities.openBalance": "Te betalen",
    "Maturities.overdueMaturities": "Achterstallige betalingen",
    "Maturities.paymentPlanOverview": "Overzicht volgende betalingstermijnen",
    "Maturities.positiveBalance": "Shoptegoed",
    "Maturities.showDetails": "Details tonen",
    "MinimalMaturities.customAmount": "Het bedrag is op jouw verzoek aangepast",
    "MinimalMaturities.grandTotal": "Minimaal te betalen",
    "MinimalMaturities.maturityDate": "Op {date}{break}wordt dit bedrag{break} geïnd",
    "MinimalMaturities.monthlyRate": "Maandbedrag",
    "MinimalMaturities.overdue": "Achterstand",
    "MyAccount.Notification.DelayedDeliveryWarning": "Vanwege de huidige overstromingen kunnen onze dienstverleners niet alle gebieden probleemloos bereiken. Daarom kan er helaas vertraging optreden bij leveringen in getroffen gebieden.{break}Bedankt voor uw begrip.",
    "MyAccountContainer.accessDenied.error": "Als EU-klant heb je helaas geen toegang tot Mijn account.",
    "Navigation.headline": "Mijn Account",
    "OverviewContainer.Kim.title": "Hoe bevalt deze overzichtspagina?",
    "OverviewContainer.ReturnsInformation.card.headline": "Retourzendingen",
    "OverviewContainer.ReturnsInformation.date": "Tegoed geïnstrueerd op:",
    "OverviewContainer.ReturnsInformation.empty.message": "Er zijn geen retourzendingen ontvangen.",
    "OverviewContainer.ReturnsInformation.goToAllReturns.text": "Alle retourzendingen",
    "OverviewContainer.ReturnsInformation.item.number.label": "Artikelnummer:",
    "OverviewContainer.ReturnsInformation.item.quantity.label": "Aantal:",
    "OverviewContainer.ReturnsInformation.item.size.label": "Maat:",
    "OverviewContainer.ShipmentInformation.card.headline": "Bestellingen onderweg",
    "OverviewContainer.ShipmentInformation.fallback.text": "Er is momenteel geen bestelling naar je onderweg.",
    "OverviewContainer.ShipmentInformation.goToAll": "Alle bestellingen & leveringen",
    "PartialPayment.AccountBalanceHeadline": "Saldo",
    "PartialPayment.AccountInformationReceiver": "Ontvanger",
    "PartialPayment.AllBookings": "Alle boekingen",
    "PartialPayment.Birthdate": "Gelieve <strong>{currentAmountDue}</strong> over te maken voor <strong>{currentAmountDueDate}</strong> naar de volgende rekening:",
    "PartialPayment.CardHeadline": "Flexirekening gespreide betaling",
    "PartialPayment.CurrentAmountDue": "Uw verschuldigde bedrag",
    "PartialPayment.InlineNotification": "Onze loyaliteitsbonus voor u: {break}U kunt nog tot {amountUntilChange} bestellen bij Universal – uw huidige maandelijkse minimale termijn blijft hetzelfde!",
    "PartialPayment.InstructionsQr": "Betaal gemakkelijk via de onderstaande QR-code in de app van jouw bank:",
    "PartialPayment.MinimumRate": "Huidige minimale termijn",
    "PartialPayment.Or": "OF",
    "PartialPayment.Purpose": "Betalingsreferentie:",
    "PartialPayment.RsvFee": "Huidige premie betalingsbescherming",
    "PartialPayment.RsvFeeIncluded": "incl. premie betalingsbescherming",
    "PartialPayment.ToMyAccountStatements": "Naar mijn rekeningoverzichten",
    "PartialPayment.openBalance": "Openstaand saldo",
    "PasswordForget.error.general": "Je verzoek kan momenteel niet worden verwerkt. Probeer het later opnieuw.",
    "PasswordForget.password.change.success": "Wachtwoord succesvol gewijzigd.",
    "PaymentPause.CardHeadline": "Flexirekening betalingspauze",
    "PaymentPause.FurtherInstructions": "Alle informatie over de Flexirekening betalingspauze",
    "PaymentPause.InactivelInstructions": "Kies de Flexirekening betalingspauze bij uw volgende aankoop in de betalingsstap en betaal pas na 3 maanden.*{break}* Toeslag 4,99% van de bestelwaarde. Kredietwaardigheid vereist.",
    "PaymentPause.InactivelSubheadline": "U heeft momenteel geen actieve betalingspauze.",
    "PaymentPause.InitialPurchaseDate": "Aankoopdatum",
    "PaymentPause.PurchaseAmount": "Bedrag",
    "PaymentPause.PurchaseDueDate": "vervalt op",
    "PaymentPause.PurchaseFrom": "Aankoop van ",
    "PaymentPause.activeInstructions": "Hier vind je een overzicht van je actieve betalingspauzes en de vervaldatum.",
    "PaymentPlan.due": "Vervalt op ",
    "PaymentPlan.empty": "Op {date} zijn geen betalingstermijnen aanwezig!",
    "PaymentPlan.error": "Uw betalingen kunnen momenteel niet worden weergegeven. Probeer het binnenkort opnieuw.",
    "PaymentPlan.headline": "Uw volgende betalingen",
    "PaymentPlan.hintHeadline": "Let op:",
    "PaymentPlan.hintText": "Boekingen uit het totaaloverzicht worden hier verdeeld over verschillende vervaldatums. De individuele facturen vindt u mogelijk onder hetzelfde documentnummer bij verschillende vervaldatums.",
    "PaymentPlan.showInvoice": "Factuur nr. {document} bekijken",
    "PaymentProtectionCard.BulletPointHead": "De Flexirekening betalingsbescherming biedt u en uw partner die in hetzelfde huishouden woont in veel onvoorziene situaties optimale zekerheid:",
    "PaymentProtectionCard.CompletePaymentProtection": "Nu de Flexirekening betalingsbescherming afsluiten",
    "PaymentProtectionCard.Enabled": "De Flexirekening betalingsbescherming is actief",
    "PaymentProtectionCard.GoodToKnow": "Goed om te weten",
    "PaymentProtectionCard.GoodToKnowText": "De verzekeringsbijdrage bedraagt 1,15% van het openstaande saldo van uw klantenrekening. Als er geen openstaand saldo is, hoeft er natuurlijk niets betaald te worden.",
    "PaymentProtectionCard.Headline": "Flexirekening betalingsbescherming",
    "PaymentProtectionCard.OldInsurance0": "Ernstige ziekten{break}<small>Bescherming bij kanker, hartaanval, beroerte, blindheid, gehoorverlies</small>",
    "PaymentProtectionCard.OldInsurance1": "Blijvende invaliditeit door ongeval{break}<small>Blijvende invaliditeit door ongeval van minimaal 50%</small>",
    "PaymentProtectionCard.OldInsurance2": "Overlijden",
    "PaymentProtectionCard.Service": "In geval van schade zijn we er voor u: <b><phonelink>0662 / 4485 - 22</phonelink></b>",
    "PaymentProtectionCard.YoungInsurance0": "Werkloosheid{break}<small>Bescherming bij onvrijwillig verlies van werk</small>",
    "PaymentProtectionCard.YoungInsurance1": "Arbeidsongeschiktheid{break}<small>Bescherming bij ziekte, ongeval, beroeps- en arbeidsongeschiktheid</small>",
    "PaymentProtectionCard.YoungInsurance2": "Zorgbehoevendheid{break}<small>Zorggeld vanaf zorgniveau 5 en hoger</small>",
    "PaymentProtectionCard.YoungInsurance3": "Ernstige ziekten{break}<small>Bescherming bij kanker, hartaanval, beroerte, blindheid, gehoorverlies</small>",
    "PaymentProtectionCard.YoungInsurance4": "Overlijden{break}<small>Bij overlijden dubbele uitkering (max. 12.000 €)</small>",
    "PaymentSettings.Headline": "Opgeslagen betaalgegevens",
    "PaymentSettings.confirmDelete": "Verwijderen kan niet ongedaan worden gemaakt!",
    "PaymentSettings.confirmDeleteButton": "verwijderen bevestigen",
    "PaymentSettings.creditCard": "Creditcard",
    "PaymentSettings.creditCard.number": "Kaartnummer",
    "PaymentSettings.creditCard.validThru": "Geldig tot",
    "PaymentSettings.deleteButton": "verwijderen",
    "PaymentSettings.directDebit": "Automatische incasso",
    "PaymentSettings.directDebit.download": "SEPA-mandaat downloaden",
    "PaymentSettings.directDebit.new": "SEPA-incassomandaat",
    "PaymentSettings.empty.addDirectDebit": "Automatische incasso instellen",
    "PaymentSettings.empty.hint.headline": "Sneller & veiliger winkelen",
    "PaymentSettings.empty.hint.text": "Je kunt tijdens een bestelling je bankgegevens opslaan, zodat deze hier worden bewaard. Dit betekent dat je de volgende keer niet alle gegevens opnieuw hoeft in te voeren, waardoor je tijd bespaart.",
    "PaymentSettings.hint.headline": "Waar komen de gegevens vandaan?",
    "PaymentSettings.hint.text": "Hier vind je de gegevens die je voor verdere aankopen hebt opgeslagen. Door op 'verwijderen' te klikken kun je de gegevens natuurlijk definitief verwijderen.",
    "PaymentSettings.successDeleteCreditCard": "Creditcardgegevens zijn succesvol verwijderd",
    "PaymentSettings.successDeleteDirectDebit": "Bankgegevens zijn succesvol verwijderd",
    "PersonalCoupon.ActiveChip": "Kortingscode is toegepast",
    "PersonalCoupon.ActiveHint": "De kortingscode wordt toegepast in stap 5 'Controleren en bestellen'.",
    "PersonalCoupon.Button": "Kortingscode inwisselen",
    "PersonalCoupon.ConditionsHeadline": "*Voorwaarden kortingscode",
    "PersonalCoupon.Header": "Jouw bedankje:",
    "PersonalData.Birthdate": "Geboortedatum",
    "PersonalData.BusinessPartnerNo": "Klantnummer",
    "PersonalData.EmailAddress": "E-mailadres",
    "PersonalData.Headline": "Persoonlijke gegevens",
    "PersonalData.Iban": "IBAN",
    "PersonalData.InvoiceAddress": "Factuuradres",
    "PersonalData.Password": "Wachtwoord",
    "PersonalData.PasswordHint": "Het wachtwoord wordt om veiligheidsredenen niet weergegeven.",
    "PersonalData.Phone": "Telefoon",
    "PersonalData.Verified.Text": "Bevestigd",
    "PersonalData.addressmodal.headline": "Nieuw adres?",
    "PersonalData.change": "wijzig",
    "PersonalData.changepassword.success": "Wachtwoord succesvol gewijzigd.",
    "PersonalData.emailmodal.headline": "Heb je een nieuw e-mailadres?",
    "PersonalData.ibanmodal.headline": "IBAN wijzigen",
    "PersonalData.passwordmodal.headline": "Nieuw wachtwoord?",
    "PersonalData.phone.fixed": "Vaste lijn",
    "PersonalData.phone.mobile": "Mobiel",
    "PersonalData.phonemodal.headline": "Houd je contactmogelijkheden actueel",
    "PersonalDataContainer.Kim.title": "Hoe bevalt de pagina Persoonlijke Gegevens?",
    "PrepaymentHint.text": "De bestelling wordt pas verwerkt als een aanbetaling van {amount} bij ons is ontvangen. Let op: bestelde artikelen zijn tot dan niet gereserveerd.{space} Snel, veilig, eenvoudig – Nu betalen via iDEAL.",
    "ProductNotFoundHint.copy": "Oudere bestelgegevens kunnen om technische redenen helaas niet getoond worden. Verzonden bestellingen kun je terugvinden onder <a>\"klantenrekening\"</a>.",
    "ProductNotFoundHint.title": "Artikel niet gevonden?",
    "ReclamationForm.Article.Submit": "Overnemen",
    "ReclamationForm.FormCancel": "Annuleren",
    "ReclamationForm.FormSubmit": "Versturen",
    "ReclamationForm.Information": "Gebruik dit formulier alleen om defecte of beschadigde artikelen te melden die gerepareerd of geïnspecteerd moeten worden. Voor alle andere vragen, gebruik alstublieft ons contactformulier op",
    "ReclamationForm.InvoiceInfo": "Jouw klacht over de factuur {invoiceNumber} van {invoiceDate}",
    "ReclamationForm.Replacement.Headline": "Heb je een vervangend onderdeel nodig? Vul dan de volgende gegevens in",
    "ReclamationForm.Shipping.Headline": "Is je bezorgadres afwijkend van je factuuradres?",
    "ReclamationForm.Shipping.Submit": "Overnemen",
    "ReclamationForm.Submit.Error": "Je klacht kon helaas niet worden verwerkt. Probeer het later opnieuw!",
    "ReclamationForm.articleDescription.required": "Artikelbeschrijving is vereist",
    "ReclamationForm.articleNumber.required": "Artikelnummer is vereist",
    "ReclamationForm.city.required": "Plaats is vereist",
    "ReclamationForm.email.required": "E-mailadres is vereist",
    "ReclamationForm.emailAddress": "E-mail:",
    "ReclamationForm.error": "Je bent momenteel niet aangemeld. Meld je eerst aan",
    "ReclamationForm.error.invalidData": "We konden je gegevens niet vinden. Probeer het later nog eens!",
    "ReclamationForm.firstName.required": "Voornaam is vereist",
    "ReclamationForm.headlineArticle": "Artikel- en bestelinformatie",
    "ReclamationForm.headlineCustomer": "Klantinformatie",
    "ReclamationForm.headlineShipping": "Afleveradres (indien afwijkend van rekeningadres)",
    "ReclamationForm.houseNumber.required": "Huisnummer is vereist",
    "ReclamationForm.labelArticleDescription": "Artikelbeschrijving:",
    "ReclamationForm.labelArticleNumber": "Artikelnummer:",
    "ReclamationForm.labelBestReachedPhoneNumber": "best bereikbaar op:",
    "ReclamationForm.labelCity": "Plaats:",
    "ReclamationForm.labelCustomerNo": "Klantnummer:",
    "ReclamationForm.labelDataTypeShield": "Typeplaatje:",
    "ReclamationForm.labelFirstName": "Voornaam:",
    "ReclamationForm.labelHouseNumber": "Huisnummer:",
    "ReclamationForm.labelLastName": "Achternaam:",
    "ReclamationForm.labelReclamationDescription": "Beschrijving van het gebrek:*",
    "ReclamationForm.labelReclamationReason": "Reden voor klacht:",
    "ReclamationForm.labelReplacementNumber": "Vervangend onderdeelnummer:",
    "ReclamationForm.labelStreet": "Straat:",
    "ReclamationForm.labelZipCode": "Postcode:",
    "ReclamationForm.lastName.required": "Achternaam is vereist",
    "ReclamationForm.primaryPhoneNumber": "Telefoon:",
    "ReclamationForm.reclamationDescription.required": "Beschrijving van het gebrek is vereist",
    "ReclamationForm.reclamationReasonDamaged": "Artikel defect",
    "ReclamationForm.reclamationReasonIncomplete": "Artikel onvolledig",
    "ReclamationForm.reclamationReasonOther": "Overig",
    "ReclamationForm.reclamationReasonPlaceholder": "Selecteer",
    "ReclamationForm.reclamationReasonWrongPrice": "Verkeerde prijs",
    "ReclamationForm.reclamationReasonWrongProduct": "Verkeerd artikel geleverd",
    "ReclamationForm.street.required": "Straat is vereist",
    "ReclamationForm.zipCode.required": "Postcode is vereist",
    "ReturnDeclaration.CTA.Button.Label": "Voeg nog een trackingnummer toe",
    "ReturnDeclaration.Description": "Is je pakket al onderweg naar ons?{br}Deel dan gewoon je tracking- of trackingnummer met ons.",
    "ReturnDeclaration.Disclaimer": "Artikelen verzonden per vracht zijn helaas uitgesloten.",
    "ReturnDeclaration.Headline": "Retourzending opgegeven?",
    "ReturnDeclaration.InfoBox.Headline": "Waar vind ik mijn trackingnummer?",
    "ReturnDeclaration.InfoBox.Text": "Je vindt je tracking- of trackingnummer op de bon die je hebt gekregen toen je je retourzending afleverde.",
    "ReturnDeclaration.Submit.Button.Label": "Trackingnummer verzenden",
    "ReturnDeclarationCard.DateInput.Error": "De inleverdatum van je retourzending ligt te ver in het verleden",
    "ReturnDeclarationCard.DateInput.Format.Error": "Corrigeer de datum (formaat: DD-MM-JJJJ)",
    "ReturnDeclarationCard.DateInput.Future.Error": "Gebruik {date} of een eerdere waarde",
    "ReturnDeclarationCard.DateInput.Label": "Inleverdatum van de retourzending",
    "ReturnDeclarationCard.InfoModal.Content": "Met de datum kunnen we je verzendgegevens uniek toewijzen in onze database.",
    "ReturnDeclarationCard.InfoModal.Headline": "Inleverdatum van de retourzending",
    "ReturnDeclarationCard.InfoModal.Subheadline": "Waarom hebben we de inleverdatum nodig?",
    "ReturnDeclarationCard.Input.Label": "Trackingnummer",
    "ReturnDeclarationCard.Input.Placeholder": "bijv. 123456789",
    "ReturnDeclarationCard.MissingInput.InlineError": "Vul je invoer aan of verwijder deze",
    "ReturnDeclarationCard.Return.Eighth": "Achtste retourzending",
    "ReturnDeclarationCard.Return.Fifth": "Vijfde retourzending",
    "ReturnDeclarationCard.Return.First": "Retourzending",
    "ReturnDeclarationCard.Return.Fourth": "Vierde retourzending",
    "ReturnDeclarationCard.Return.Ninth": "Negende retourzending",
    "ReturnDeclarationCard.Return.Second": "Tweede retourzending",
    "ReturnDeclarationCard.Return.Seventh": "Zevende retourzending",
    "ReturnDeclarationCard.Return.Sixth": "Zesde retourzending",
    "ReturnDeclarationCard.Return.Tenth": "Tiende retourzending",
    "ReturnDeclarationCard.Return.Third": "Derde retourzending",
    "ReturnDeclarationContainer.Success.Description": "Nu we weten dat je pakket onderweg is naar ons, kunnen we dit direct in onze systemen noteren.{br}{br}We nemen contact met je op zodra je pakket bij ons is aangekomen!",
    "ReturnDeclarationContainer.Success.GoToShop": "Terug naar de winkel",
    "ReturnDeclarationContainer.Success.Headline": "Hartelijk dank!",
    "ReturnDeclarationContainer.accessDenied.error": "Deze link is helaas niet geldig.",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Bring": "Zo kun je je artikel naar ons terugsturen",
    "ReturnShipmentInfo.InstructionsForReturn.contactHeading.Pickup": "Zo kun je je vrachtartikel naar ons terugsturen",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Bring": "Gebruik gewoon het retourlabel dat bij je zending zit. Neem anders contact op met onze klantenservice via <link>{emailAddress}</link> of op {phoneNumber}.",
    "ReturnShipmentInfo.InstructionsForReturn.contactMessage.Pickup": "Neem contact op met onze klantenservice op {phoneNumber} voor het ophalen van je retourzending.",
    "ReturnShipmentInfo.InstructionsForReturn.emailAddress": "klantenservice@lascana.nl",
    "ReturnShipmentInfo.InstructionsForReturn.errorToast": "Oeps. Er is iets misgegaan. {break} Probeer het later opnieuw of neem contact op met onze klantenservice op {phoneNumber}.",
    "ReturnShipmentInfo.InstructionsForReturn.href": "/service-hilfe/kontakt",
    "ReturnShipmentInfo.InstructionsForReturn.noteHeading": "Let op:",
    "ReturnShipmentInfo.InstructionsForReturn.noteMessage": "Stuur alleen artikelen uit dezelfde levering in één pakket terug naar ons, zodat we je retourzending kunnen verwerken en duidelijk kunnen toewijzen. Als je meer artikelen uit andere leveringen wilt retourneren, moeten deze per levering worden verpakt en teruggestuurd.",
    "ReturnShipmentInfo.InstructionsForReturn.packingHeading": "1. Verpakken",
    "ReturnShipmentInfo.InstructionsForReturn.packingMessage": "Verpak je artikel in het pakket - bij voorkeur in het pakket dat we al hebben gebruikt. Vergeet ook niet de pakbon met de retourredenen bij te voegen.",
    "ReturnShipmentInfo.InstructionsForReturn.parcelHeading": "3. PakketShop",
    "ReturnShipmentInfo.InstructionsForReturn.parcelMessage": "Lever je retourzending in bij een Hermes PakketShop.",
    "ReturnShipmentInfo.InstructionsForReturn.phoneNumber": "09572 - 50 50",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelHeading": "2. Retourlabel",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage": "Je kunt hier een nieuw retourlabel laten maken, dat je kunt uitprinten en op het pakket kunt plakken. De rest wordt door Hermes geregeld.",
    "ReturnShipmentInfo.InstructionsForReturn.printLabelMessage2": "<semiBold>Let op: </semiBold> Als je het bijgevoegde retourlabel al hebt gebruikt, neem dan contact op met onze klantenservice op {phoneNumber} of {emailAddress} voor een nieuw label. Alleen zo komt je retourzending ook echt bij ons aan.",
    "ReturnShipmentInfo.InstructionsForReturn.relaxHeading": "4. Ontspannen",
    "ReturnShipmentInfo.InstructionsForReturn.relaxMessage": "Daarna kun je lekker achterover leunen. Zodra je retourzending door ons is verwerkt, informeren we je per e-mail en betalen we eventueel al betaalde bedragen terug.",
    "ReturnShipmentInfo.modalMainHeader.Before": "Retourlabel aanmaken",
    "ReturnShipmentInfo.modalMainHeader.Bring": "Artikel retourneren",
    "ReturnShipmentInfo.modalMainHeader.Online": "Retourlabel aanmaken",
    "ReturnShipmentInfo.modalMainHeader.Pickup": "Artikel retourneren",
    "ReturnShipmentInfo.modalSubHeader.Before": "Zo kun je je artikel aan ons terugsturen",
    "ReturnShipmentInfo.modalSubHeader.Online": "Zo kun je je artikel aan ons terugsturen",
    "ReturnShipmentInfo.phoneNumber": "09572 - 50 50",
    "ReturnShipmentInfo.returnType.Before": "Retourlabel weergeven",
    "ReturnShipmentInfo.returnType.Bring": "Terug naar Mijn bestellingen",
    "ReturnShipmentInfo.returnType.Online": "Retourlabel weergeven",
    "ReturnShipmentInfo.returnType.Pickup": "Terug naar Mijn bestellingen",
    "ServiceContactTile.callUs": "Neem contact met ons op",
    "ServiceContactTile.questions": "Heb je vragen? We staan voor je klaar",
    "ServiceContactTile.write": "Mail ons of stel je vraag via de chat",
    "ServiceContactTile.yourCustomerNumber": "Jouw klantnummer",
    "ShipmentCardAction.RecoSlider.headline": "Aanbevelingen voor jou",
    "ShipmentCardAction.similarproducts.link": "Vergelijkbare artikelen",
    "ShipmentCardDetails.hint.delivered": "Details over de bezorging van je pakket vind je in de trackinginformatie.",
    "ShipmentCardDetails.hint.downpayment": "Houd rekening met onze aanbetalinginstructies bij de afzonderlijke artikelen",
    "ShipmentCardDetails.label.carrier": "Verzendpartner",
    "ShipmentCardDetails.label.deliveryAddress": "Afleveradres",
    "ShipmentCardDetails.label.deliveryService": "Bezorgservice",
    "ShipmentCardDetails.label.hint": "Opmerking",
    "ShipmentCardDetails.label.invoiceLink": "Factuur bekijken",
    "ShipmentCardDetails.label.noTrackingAvailable": "Es sind keine Track & Trace-Informationen verfügbar",
    "ShipmentCardDetails.label.numberOfPackages": "Aantal pakketten",
    "ShipmentCardDetails.label.orderDate": "Besteldatum",
    "ShipmentCardDetails.label.packageType": "Verpakkingstype",
    "ShipmentCardDetails.label.paymentType": "Betaalwijze",
    "ShipmentCardDetails.label.trackingId": "Tracking ID",
    "ShipmentCardDetails.link.desiredDelivery": "Gewenste levering {optPackageNo}",
    "ShipmentCardDetails.link.returnLabelShow": "Retourlabel weergeven",
    "ShipmentCardDetails.link.tracking": "Tracking {optPackageNo}",
    "ShipmentCardDetails.package.indicator": "(Pakket {current} van {total})",
    "ShipmentCardDetails.returnLabelCreated": "Retourlabel aangemaakt",
    "ShipmentCardDetails.trackingUrlError": "Etwas ist schief gelaufen. Bitte versuche es später erneut.",
    "ShipmentInfoCard.details.headline": "Besteldetails & Tracking",
    "ShipmentInfoHeader.headline": "Bestellingen",
    "ShipmentInfoHeader.text": "Hier vind je een overzicht van de status van jouw bestellingen en leveringen.",
    "ShipmentInfoTypeSelect.option.all": "Alle",
    "ShipmentInfoTypeSelect.option.canceled": "Geannuleerd",
    "ShipmentInfoTypeSelect.option.delivered": "Geleverd",
    "ShipmentInfoTypeSelect.option.label": "Leveringsstatus:",
    "ShipmentInfoTypeSelect.option.ordered": "Besteld",
    "ShipmentInfoTypeSelect.option.returned": "Geretourneerd",
    "ShipmentInfoTypeSelect.option.sent": "Onderweg",
    "ShipmentInfos.cancel.error.message": "Er is een fout opgetreden bij het annuleren van de bestelling.",
    "ShipmentInfos.headline": "Bestellingen sneller vinden:",
    "ShipmentInfos.loadMore": "meer laden",
    "ShipmentInfos.nodata-hint": "Kies je gewenste periode of bezorgstatus.",
    "ShipmentInfos.request.error.message": "Er is een fout opgetreden bij het laden van de bestellingen.",
    "ShipmentInfosContainer.Kim.title": "Hoe bevalt de pagina Mijn bestellingen en leveringen?",
    "ShipmentInfosProductView.label.amount": "Aantal",
    "ShipmentInfosProductView.modal.message.canceled.main": "Je artikel is gestorneerd.",
    "ShipmentInfosProductView.modal.message.canceled.sub": "De volgende keer vind je vast iets wat bij je past!",
    "ShipmentInfosProductView.modal.title": "Weet je zeker dat je dit artikel wilt annuleren?",
    "ShipmentInfosProductView.modal.title.success": "Annulering geslaagd",
    "ShipmentInfosProductView.productLink": "naar artikel",
    "ShipmentInfosShipmentListType.all": "Alle",
    "ShipmentInfosShipmentListType.awaiting_downpayment": "Wachten op aanbetaling",
    "ShipmentInfosShipmentListType.canceled": "Geannuleerd",
    "ShipmentInfosShipmentListType.delivered": "Geleverd",
    "ShipmentInfosShipmentListType.in_delivery": "In levering",
    "ShipmentInfosShipmentListType.ordered": "Besteld",
    "ShipmentInfosShipmentListType.returnLabel": "Retourlabel",
    "ShipmentInfosShipmentListType.return_shipment": "Retourzending",
    "ShipmentInfosShipmentListType.returned": "Geretourneerd",
    "ShipmentInfosShipmentListType.returnlabel_created": "Retourlabel aangemaakt",
    "ShipmentInfosShipmentListType.sent": "Onderweg",
    "ShipmentInfosShipmentListType.shipment_prepared": "Verzending voorbereid",
    "ShipmentTimeRangeSelect.option.all": "Alle",
    "ShipmentTimeRangeSelect.option.days": "{amount} dagen",
    "ShipmentTimeRangeSelect.option.label": "Periode:",
    "ShipmentTimeRangeSelect.option.weeks": "{amount} weken",
    "VerificationNotification.Text": "Bevestig je e-mailadres om de <strong>veiligheid van je account te vergroten</strong>.",
    "VerificationNotification.VerifyAgainCTA": "E-mail opnieuw aanvragen",
    "VerificationNotification.VerifyAgainText": "We hebben je een <strong>bevestigingslink</strong> gestuurd naar <strong>{email}</strong>. Geen e-mail ontvangen? Controleer eventueel je spammap. Of je kunt de...",
    "VerificationNotification.VerifyCTA": "E-mailadres bevestigen",
    "VerificationNotification.error": "Helaas is dat niet gelukt, probeer het nog eens",
    "blackFridayNotificationText": "Vanwege de zeer hoge orderinstroom vragen wij je begrip dat de levering momenteel 2-3 dagen langer kan duren!",
    "meta.title.addressbook": "Mijn Adresboek | Mijn Account",
    "meta.title.bankstatements": "Mijn Rekeningoverzicht | Mijn Account",
    "meta.title.callcenterLogin": "Callcenter Login | Mijn Account",
    "meta.title.default": "Mijn Account",
    "meta.title.flexikonto": "Mijn Flexikonto | Mijn Account",
    "meta.title.overview": "Overzicht | Mijn Account",
    "meta.title.passwordForget": "Wachtwoord vergeten",
    "meta.title.personaldata": "Mijn persoonlijke gegevens | Mijn Account",
    "meta.title.premiumlounge": "Mijn Lounge | Mijn Account",
    "meta.title.shipmentinfos": "Mijn Bestellingen & leveringen | Mijn Account",
    "AccountInformation.bic": "BIC: ",
    "AccountInformation.iban": "IBAN: NL30INGB0007206580",
    "AccountInformation.receiverAdditionals": "Bankinformatie",
    "AccountInformation.receiverName": "LASCANA",
    "ServiceContactTile.emailAddress": "klantenservice@lascana.nl",
    "ServiceContactTile.emailAddressEmployee": "-",
    "ServiceContactTile.phoneNumber": "-",
    "ServiceContactTile.phoneNumberEmployee": "-",
    "ServiceContactTile.phoneTimes": "-",
    "ServiceContactTile.phoneTimesEmployee": "-"
  }
};
  
  export default deepmerge(ioLocale, messages);
  